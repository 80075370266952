import React from "react";
import { Link } from "gatsby";

function MoreContent({ text, path, state, white }) {
  return (
    <div className={`lg:block mx-auto pt-8`}>
      <Link to={`/${path}`} state={state}>
        <div className="relative group bg-red-50 bg-opacity-50 flex items-center p-2 py-4 rounded-xl">
          <Bar />
          <h2
            className={`relative z-10 text-lg  font-display ${
              white
                ? "text-gray-700 group-hover:text-1"
                : "text-1-dark group-hover:text-red"
            }  transition duration-500 `}
          >
            {text}
          </h2>
          <Bar />
        </div>
      </Link>
    </div>
  );
}

const Bar = () => (
  <div className="w-10 h-0.5 bg-1 group-hover:bg-red mx-4 transition duration-500" />
);

export default MoreContent;
